.root {
  z-index: -1;
  padding: 16px;
}

.info {
  color: rgba(39, 37, 34, 0.7);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: 2px;
}
