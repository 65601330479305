.hideClose {
  visibility: hidden;
}

.prevArrowBtn {
  top: unset !important;
  position: absolute !important;
  bottom: 24px !important;
  left: 24px !important;
  transform: rotate(180deg);
  padding: 0 !important;
}

.nextArrowBtn {
  top: unset !important;
  position: absolute !important;
  bottom: 24px !important;
  right: 24px !important;
  padding: 0 !important;
}

.closeBtn {
  min-width: 0 !important;
}
