.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 76px;
}

.root > h2,
p {
  margin: 0px !important;
}

.root h2 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.root p {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.imgRoot {
  width: fit-content;
  max-width: 100%;
}

.actions {
  display: flex;
  gap: 24px;
}

@media screen and (max-width: 768px) {
  .root {
    margin-left: 0px;
    margin-top: 24px;
  }
}
