.table {
  padding: 16px 32px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.formSelector {
  min-width: 35%;
  width: 400px;
}

.tag {
  width: max-content;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.columnHeader {
  margin: 8px 0;
}

.questions {
  margin: 16px 0;
}

.responses {
  padding: 20px 0;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.emptyFormResponses {
  margin-top: 64px;
}

.responseColumnHeader {
  margin: 8px 0;
  min-width: 200px;
}

.responseColumnHeaderDesc {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.46px;
}

.formQuestions {
  margin-left: -12px;
}
