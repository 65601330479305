.entireFooterWrapper {
  position: relative;
  bottom: 0px;
}

.footerRulesWrapper {
  padding: 12px 17px;
  background-color: #f6f4ff;
  color: #493ab1;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}

.customFooter {
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paper {
  border: none !important;
}

@media screen and (max-width: 768px) {
  .entireFooterWrapper {
    position: fixed;
    width: 100%;
  }
}
