.exlyTableRoot .exly-creator-tool--MuiToolbar-root,
.exlyTableRoot .exly--MuiToolbar-root {
  display: none;
}

.listContentRoot {
  width: 0;
}

.tableWrapper {
  width: 100%;
  overflow-y: auto;
  max-height: 60vh;
  border-radius: 8px;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.14);
}
