.root {
  overflow-y: auto;
  height: 100%;
}

.backdrop {
  background-color: rgba(39, 37, 34, 0.3);
}

.padded {
  padding: 0px 24px;
}

.desktopModalPaper {
  width: var(--desktop-modal-width);
  max-width: var(--desktop-modal-width);
}

@media screen and (max-width: 780px) {
  .padded {
    padding: 0px 16px;
  }
}
