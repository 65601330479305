.add_cta {
  text-transform: none !important;
  color: #493ab1 !important;
}

.notification {
  width: fit-content;
  padding: 8px 12px;
  background-color: #f6f4ff;
  border-radius: 5px;
}
