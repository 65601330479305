.fields {
  display: flex;
  gap: 16px;
}

.dropdown {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .fields {
    flex-direction: column;
  }

  .dropdown {
    width: 100%;
  }
}
