.root {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #e7e6e4;
  color: #272522;
}

.root div:nth-child(1) {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 8px;
}

.root div:nth-child(2) {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .root {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .root div:nth-child(1) {
    font-size: 16px;
  }

  .root div:nth-child(2) {
    font-size: 14px;
  }
}
