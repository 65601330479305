.root {
  height: calc(100vh - 123.23px);
  background: #eeecf8;
  padding: 32px 48px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.root::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .root {
    height: auto;
    padding: 16px;
    padding-bottom: 80px;
  }
}
