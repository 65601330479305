.root {
  font-size: 12px;
  line-height: 14.32px;
  color: #2e3033;
  font-weight: 400;
  background-color: #e5f0ff;
  padding: 8px 16px;
}

.root a {
  color: #0052cc;
}

.root svg {
  font-size: 14px;
  color: #0052cc;
  margin-top: -2px;
}
