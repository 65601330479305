.titleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.check {
  color: #b8b4ad;
}

.completed {
  color: #00b77a;
}

.body {
  padding: 14px 14px 24px;
}
