.featureContainer {
  overflow: hidden;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: 11;
  background-color: white;
}

.featureImage {
  width: 100%;
}

.featureTextContainer {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
}

.innerBox {
  width: 65%;
  text-align: center;
}

.heading {
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  color: #272522;
}

.description {
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  color: #4e4a44;
}

.lockIcon {
  width: 26px;
  height: 35px;
  margin-bottom: 16px;
  color: #ffab00;
}

.upgradeBtn {
  width: fit-content;
  padding: 5px 8px;
  background-color: #ffab00;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .featureTextContainer {
    padding: 16px;
    bottom: 6px;
  }

  .innerBox {
    width: 100%;
  }
}
