.modalPaperClassName {
  min-width: 532px;
}

.warningIcon {
  color: #bf2600;
}

.desktopModalHeader {
  display: flex;
  align-items: center;
  gap: 10.47px;
  height: 22px;
}

.mobileModalHeader {
  display: flex;
  align-items: center;
  gap: 12.94px;
  height: 22px;
}

.desktopWarningWrapper {
  display: flex;
  flex-direction: column;
  gap: 9.81px;
  padding: 24px 0px;

  & svg {
    font-size: 97.38px;
    color: #bf2600;
  }
}

.mobileWarningWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.81px;
  padding: 16px 0px;

  & svg {
    font-size: 97.38px;
    color: #bf2600;
  }
}

.primaryBtnClassName {
  background-color: #bf2600 !important;
  padding: 0px 16px;
}
