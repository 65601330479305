.root {
  font-size: 12px;
  line-height: 14.32px;
  color: #2e3033;
  font-weight: 400;
}

.root a {
  color: #0052cc;
}

.root svg {
  font-size: 14px;
  color: #0052cc;
  margin-top: -2px;
}

.pageTitle {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 8px;
}
