.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 76px;
}

.media {
  aspect-ratio: 16 / 9;
  width: 650px !important;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .root {
    margin-left: 0px;
    margin-top: 24px;
  }

  .media {
    min-width: 0px;
    width: 100% !important;
  }
}
