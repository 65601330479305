.remove {
  text-transform: none !important;
}

.body {
  background-color: #f6f6f6;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.whiteBackground {
  background-color: white;
}

.dropdown {
  background-color: white;
  height: 43px;
  border: 1.5px solid #cfcdc9;
  border-radius: 5px;
}

.drag_handle {
  margin-top: 24.5px;
}

.fields {
  display: flex;
  gap: 8px;
}

.dropdownWrapper {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .fields {
    flex-direction: column;
  }

  .dropdownWrapper {
    width: 100%;
  }
}
