.table {
  height: 100%;
  margin: -16px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
  height: 100%;
}

.paddedContainer {
  padding-bottom: 52px;
}

.body {
  margin-top: 16px;
  padding: 0 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paginationWrapper {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  border-top: 1.5px solid var(--THEME_PALETTE_SECONDARY_SHADE_100);
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circularProgress {
  height: 24px !important;
  width: 24px !important;
}

.emptyFormState {
  margin-top: 32px;
  margin-left: 8px;
}
