.info {
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.09px;
  text-align: left;
  margin-bottom: 8px;
}

.star {
  color: #ff0c00;
}

.option {
  cursor: pointer;
  padding: 6px 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.option:hover {
  background: #e0dfdf;
}

.selectedOption {
  background-color: #e0dfdf;
}
