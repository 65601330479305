.root {
  padding: 24px;
  border-left: 1px solid #e4e4e4;
  height: 100%;
}

.placeholder_root {
  animation: none !important;
  max-width: 18.1vw;
  margin: 0 auto;
}

.body {
  white-space: pre-wrap;
}

.labels {
  text-align: center;
  margin-bottom: 24px;
}

.labels div:nth-child(1) {
  color: #272522;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
}

.labels div:nth-child(2) {
  color: #959595;
  font-size: 0.875rem;
  line-height: normal;
}

.whatsappChatBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.whatsappChatBodyText {
  background-color: #fff !important;
  white-space: pre-wrap;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.3;
  word-break: break-all;
  width: 100%;
}

.headerText {
  font-weight: bold;
  margin-bottom: 8px;
}

.whatsappChatBodyTextPreview {
  color: #777;
  padding: 56px 12px;
  text-align: center;
}

.footerText {
  color: rgba(39, 37, 34, 0.6);
  margin-bottom: 12px;
  margin-top: 14px;
}

.buttonsContainer {
  border-top: 0.5px solid rgba(39, 37, 34, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
}

.buttonWrapper {
  display: flex;
  color: #0088ea;
  gap: 4px;
  align-items: center;
}

.media {
  margin: 0 0 8px !important;
  width: auto !important;
  transform: none !important;
}

.template_content {
  max-width: 88%;
}

@media screen and (max-width: 768px) {
  .placeholder_root {
    max-width: none;
  }
}
