.root {
  padding: 24px 0px 24px 24px;
  max-height: calc(100vh - 123.23px);
  overflow-y: auto;
}

.root::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px;
    max-height: none;
    overflow-y: visible;
  }
}
