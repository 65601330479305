.card {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1.5px solid #dfdfdf;
}

.singleformbody {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.singleFormAnswer {
  height: 100%;
  width: 100%;
}

.singleFormAnswerTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.54px;
  text-align: left;
  margin-bottom: 12px;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.54px;
  text-align: left;
  border-bottom: 1px solid #dfdfdf;
}

.body {
  padding: 12px 16px;
}

/** Negative margin for free padding added to base component*/
.textBody {
  margin: 0 -12px;
  margin-bottom: -14px;
}

.formNotAttempedYet {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.7px;
  letter-spacing: 0.02em;
  text-align: left;
}

.dashedDivider {
  border: 1px dashed #e2dff7;
}
