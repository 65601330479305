.container {
  background-color: #f2f7ff;
  border-radius: 4px;
  max-width: max-content;
}

.body {
  color: #166cd1;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.7px;
  letter-spacing: 0.02em;
  text-align: left;
}
