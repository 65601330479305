.container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 24px;
}

.pageSize {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
  align-items: center;
}

.pageNumber {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.showingPageText {
  color: #27252299;
}

.input {
  width: 64px;
}

.disabled {
  pointer-events: none;
}

.disabledButton {
  color: red !important;
}

.actionButtonFlex {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.actionButtonIcon {
  cursor: pointer;
  font-size: 16px !important;
  line-height: 16px !important;
}
