.root {
  padding: 16px !important;
}

.label {
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.28px;
  margin-top: 24px;
}

.paper {
  width: 468px !important;
}
